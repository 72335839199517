.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0, .6);
  opacity: 0;
  transition: all 0.3s ease 0s;
  pointer-events: none;
  visibility: hidden;
  z-index: 10;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

}
