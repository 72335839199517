.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 40px 60px;
  transition: visibility 0.7s ease 0.2s;
  visibility: hidden;
  pointer-events: none;
  z-index: 100000;
  width: 720px;
  min-height: 539px;
  border: 2px solid #4C4CFF;
  color: #000;
  background: #1C1C21;
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  &.open {
    visibility: visible;
    pointer-events: all;
  }
}

.modal-close {
  position: absolute;
  right: 40px;
  top: 60px;
  z-index: 2;
  cursor: pointer;
}


