.tabs {
  display: flex;
}

.lists {
  max-height: calc(100vh - 330px);
  padding-bottom: 30px;
  overflow-y: scroll;
}

.list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 32px;
}

.burgerItem {
  flex: 0 1 calc(50% - 12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.count {
  position: absolute;
  top: 0;
  right: 0;
}

.price {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  font-family: 'Iceland', Arial, sans-serif;
}

.content {
  flex: 0 1 50%;
  max-width: 600px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0 16px;
}