.App {
  max-width: 1310px;
  margin: 0 auto;
  padding: 0 15px;
}

.content {
  display: flex;
  padding: 0 16px;
  gap: 40px;
  align-items: flex-start;
}