.content {
  text-align: center;
}

.title {
  text-shadow: 0px 0px 16px rgba(51, 51, 255, 0.25), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 4px 32px rgba(51, 51, 255, 0.5);
  color: #F2F2F3;
}

.subtitle {
  color: #F2F2F3;
}

.image {
}

.text {
  color: #F2F2F3;
}

.textGray {
  color: #8585AD;
}